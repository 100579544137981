import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import Content, { HTMLContent } from '../components/layout/Content'

export const MentionLegalePageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
      <section className="section section--gradient">
        <div className="container">
          <div className="columns"  style={{ textAlign: 'justify' }}>
            <div className="column is-12">
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </section>
  )
}

MentionLegalePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const MentionLegalePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
      <Layout>
        <MentionLegalePageTemplate
            contentComponent={HTMLContent}
            title={post.frontmatter.title}
            content={post.html}
        />
      </Layout>
  )
}

MentionLegalePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MentionLegalePage

export const MentionLegalePageQuery = graphql`
  query MentionLegalePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
